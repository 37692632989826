import React from 'react'
import PropTypes from 'prop-types'
import url from 'url'

import config from '../../../utils/siteConfig'
import ArticleMeta from './ArticleMeta'
import WebsiteMeta from './WebsiteMeta'
import AuthorMeta from './AuthorMeta'

/**
 * MetaData will generate all relevant meta data information incl.
 * JSON-LD (schema.org), Open Graph (Facebook) and Twitter properties.
 *
 */
const MetaData = ({
    data,
    settings,
    title,
    description,
    image,
    location,
    type,
}) => {
    const canonical = url.resolve(config.siteUrl, location.pathname)
    // settings = settings.allGhostSettings.edges[0].node
    settings = config

    if (type === `article`) {
        return (
            <ArticleMeta
                data={data.markdownRemark}
                canonical={canonical}
            />
        )
    } else if (type === `series`) {
        return (
            <WebsiteMeta
                data={data}
                canonical={canonical}
                type="Series"
            />
        )
    } else if (type === `profile`) {
        return (
            <AuthorMeta
                data={data.allMarkdownRemark}
                canonical={canonical}
            />
        )
    } else if (type === `website`) {
        return (
            <WebsiteMeta
                data={data.markdownRemark}
                canonical={canonical}
                type="WebSite"
            />
        )
    } else {
        title = title || config.siteTitleMeta || settings.title
        description = description || config.siteDescriptionMeta || settings.description
        image = image || settings.cover_image || null

        image = image ? url.resolve(config.siteUrl, image) : null

        return (
            <WebsiteMeta
                data={{}}
                canonical={canonical}
                title={title}
                description={description}
                image={image}
                type="WebSite"
            />
        )
    }
}

MetaData.defaultProps = {
    data: {},
}

MetaData.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
        // ghostTag: PropTypes.object,
        // ghostAuthor: PropTypes.object,
        // ghostPage: PropTypes.object,
    }).isRequired,
    config: PropTypes.object,
    // settings: PropTypes.shape({
    //     allGhostSettings: PropTypes.object.isRequired,
    // }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
}

// const MetaDataQuery = props => (
//     <StaticQuery
//         query={graphql`
//             query GhostSettingsMetaData {
//                 allGhostSettings {
//                     edges {
//                         node {
//                             title
//                             description
//                         }
//                     }
//                 }
//             }
//         `}
//         render={data => <MetaData settings={data} {...props} />}
//     />
// )

export default MetaData
